import "./App.css";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
const PAGE_URL = "./pages/index.md";
function App() {
  const [markdown, setMarkdown] = useState("");
  useEffect(() => {
    import(`${PAGE_URL}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setMarkdown(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  });

  return <ReactMarkdown>{markdown}</ReactMarkdown>;
}

export default App;
